<template>
  <div class="layout-header-section d-lg-flex d-block justify-content-between">
    <div class="header-label col-lg-6 col-12">Company Details</div>
    <div class="
        col-lg-6 col-12
        text-end
        d-flex
        justify-content-end
        custom-flex-cloumn-mob
      ">
      <button type="button" class="header-btn-outer btn btn-primary" @click="addCompanyModalOpen()">
        Add Company
      </button>
    </div>
  </div>

  <div class="company-detail-box-outer" style="height: calc(100vh - 141px)">
    <div v-if="!showdataloader">
      <div class="company-box-outer" v-for="(company, index) in companyList" v-bind:key="index">
        <div class="row">
          <div class="col-lg-12 col-md-12 col-12">
            <div class="d-flex justify-content-between">
              <div class="customer-label-group">
                <div class="d-flex align-items-center">
                  <div class="flex-shrink-0">
                    <span v-if="company.al4 != null && company.al4 != ''">
                      <img :src="company.al4" class="myprofile-avatar" alt="" width="60" height="60" />
                    </span>

                    <span v-if="company.al4 == null || company.al4 == ''">
                      <img src="/assets/images/company-logo.svg" class="myprofile-avatar rounded-circle" alt="" width="60"
                        height="60" />
                    </span>
                  </div>
                  <div class="
                        flex-grow-1
                        ms-3
                        company-legal-name
                        text-capitalize
                      ">
                    {{ company.al2 ? company.al2 : "N/A" }}
                  </div>
                </div>
              </div>
              <div class="customer-label-group d-flex align-items-center">
                <div class="me-4">
                  <label class="form-label me-3">Billing Status : </label>
                  <InputSwitch v-model="company.al14" :true-value="dynamicTrueActiveValue"
                    :false-value="dynamicFalseInactiveValue" @change="changeStatus(company)" />
                  <!-- <span v-if="company.al15 == 1" class="status-active ms-2">Active</span>
                  <span v-if="company.al15 == 0" class="status-inactive ms-2">Inactive</span> -->
                </div>
                <button type="button" @click="editCompanyDetailsModalOpen(company)" class="btn custom-view-detail-btn">
                  Edit Details
                </button>
              </div>
            </div>
          </div>
        </div>
        <div class="row g-2">
          <div class="col-lg-4 col-md-4 col-12">
            <div class="customer-label-group">
              <label class="form-label">Legal Name</label>
              <div class="from-label-value text-capitalize">
                {{ company.al3 ? company.al3 : "N/A" }}
              </div>
            </div>
          </div>
          <div class="col-lg-3 col-md-3 col-12">
            <div class="customer-label-group">
              <label class="form-label">Company Type</label>
              <div class="from-label-value text-capitalize">
                {{ company.al9 ? company.al9 : "N/A" }}
              </div>
            </div>
          </div>
          <div class="col-lg-3 col-md-3 col-12">
            <div class="customer-label-group">
              <label class="form-label">GST No.</label>
              <div class="from-label-value text-uppercase">
                {{ company.al6 ? company.al6 : "N/A" }}
              </div>
            </div>
          </div>
          <div class="col-lg-2 col-md-2 col-12">
            <div class="customer-label-group">
              <label class="form-label">PAN No.</label>
              <div class="from-label-value text-uppercase">
                {{ company.al5 ? company.al5 : "N/A" }}
              </div>
            </div>
          </div>
          <div class="col-lg-4 col-md-4 col-12">
            <div class="customer-label-group">
              <label class="form-label">Area</label>
              <div class="from-label-value text-capitalize">
                {{ company.al12 ? company.al12 : "N/A" }}
              </div>
            </div>
          </div>
          <div class="col-lg-6 col-md-6 col-12">
            <div class="customer-label-group">
              <label class="form-label">Address</label>
              <div class="from-label-value">
                {{ company.al7 ? company.al7 : "N/A" }}
              </div>
            </div>
          </div>
          <div class="col-lg-2 col-md-2 col-12">
            <div class="customer-label-group">
              <label class="form-label">Pincode</label>
              <div class="from-label-value text-capitalize">
                {{ company.al13 ? company.al13 : "N/A" }}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="company-box-outer" v-if="companyList == ''">
        <div class="row">
          <div class="col-lg-12 col-md-12 col-12">
            <div class="pb-3">No records found.</div>
          </div>
        </div>
      </div>
    </div>
    <div v-if="showdataloader" style="height: 430px">
      <div class="custom-modal-spinner-loader">
        <i class="pi pi-spin pi-spinner" style="font-size: 2rem"></i>
      </div>
    </div>
  </div>
  <!-- Add Company modal start here -->
  <div class="modal-mask" v-if="addcompanymodalstatus">
    <div class="
        modal-dialog modal-lg modal-dialog-scrollable modal-dialog-centered
        custom-modal-outer
      ">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="staticBackdropLabel">
            Add Company Details
          </h5>
          <button type="button" class="btn-close" @click="addCompanyModalClose()"></button>
        </div>
        <div class="modal-body">
          <div class="company-modal-fixed-height">
            <div class="row" v-if="!showaddmodalloader">
              <div class="col-12">
                <div class="row">
                  <div class="col-lg-6 col-md-6 col-12">
                    <div class="custom-form-group">
                      <label class="form-label">Marketing Name<span class="text-danger">*</span></label>
                      <input type="text" v-model="add.marketingname" class="form-control"
                        placeholder="Enter Marketing Name" autocomplete="off" />
                      <div class="custom-error" v-if="v$.add.marketingname.$error">
                        {{ v$.add.marketingname.$errors[0].$message }}
                      </div>
                    </div>
                  </div>
                  <div class="col-lg-6 col-md-6 col-12">
                    <div class="custom-form-group">
                      <label class="form-label">Legal Name<span class="text-danger">*</span></label>
                      <input type="text" v-model="add.legalname" class="form-control"
                        placeholder="Enter Legal Name" autocomplete="off" />
                      <div class="custom-error" v-if="v$.add.legalname.$error">
                        {{ v$.add.legalname.$errors[0].$message }}
                      </div>
                    </div>
                  </div>
                  <div class="col-lg-6 col-md-6 col-12">
                    <div class="custom-form-group">
                      <label class="form-label">Company Type<span class="text-danger">*</span></label>
                      <Multiselect v-model="add.companytype" :options="companytypelist" :searchable="true" label="label"
                        placeholder="Select Company Type" class="multiselect-custom text-capitalize" :canClear="true"
                        :closeOnSelect="true" noOptionsText="No Result Found" :object="true" />
                      <div class="custom-error" v-if="v$.add.companytype.$error">
                        {{ v$.add.companytype.$errors[0].$message }}
                      </div>
                    </div>
                  </div>
                  <div class="col-lg-6 col-md-6 col-12">
                    <div class="custom-form-group">
                      <label class="form-label">GST No.<span class="text-danger">*</span></label>
                      <input type="text" v-model="add.gstnumber" @input="validateAddGstNumber($event)"
                        class="form-control text-uppercase" placeholder="Enter GST No." maxlength="15"
                        autocomplete="off" />
                      <div class="custom-error" v-if="this.errmsg.erroraddgstnumber">{{
                        this.errmsg.erroraddgstnumber
                      }}</div>
                      <div class="custom-error" v-if="v$.add.gstnumber.$error">
                        {{ v$.add.gstnumber.$errors[0].$message }}
                      </div>
                    </div>
                  </div>
                  <div class="col-lg-6 col-md-6 col-12">
                    <div class="custom-form-group">
                      <label class="form-label">PAN No.<span class="text-danger">*</span></label>
                      <input type="text" v-model="add.pannumber" class="form-control text-uppercase"
                        placeholder="Enter PAN No." @input="validateAddPanCardNumber($event)" maxlength="10"
                        autocomplete="off" />
                      <div class="custom-error" v-if="this.errmsg.erroraddpannumber">{{
                        this.errmsg.erroraddpannumber
                      }}</div>
                      <div class="custom-error" v-if="v$.add.pannumber.$error">
                        {{ v$.add.pannumber.$errors[0].$message }}
                      </div>
                    </div>
                  </div>
                  <div class="col-lg-6 col-md-6 col-12">
                    <div class="custom-form-group">
                      <label class="form-label">Area<span class="text-danger">*</span></label>
                      <input type="text" v-model="add.area" class="form-control text-capitalize"
                        placeholder="Enter Area Name" autocomplete="off" />
                      <div class="custom-error" v-if="v$.add.area.$error">
                        {{ v$.add.area.$errors[0].$message }}
                      </div>
                    </div>
                  </div>
                  <div class="col-lg-6 col-md-6 col-12">
                    <div class="custom-form-group">
                      <label class="form-label">Address<span class="text-danger">*</span></label>
                      <textarea type="text" v-model="add.address" class="form-control custom-text-area-scroll"
                        placeholder="Enter Address" autocomplete="off" rows="2" maxlength="250"></textarea>
                      <div class="custom-error" v-if="v$.add.address.$error">
                        {{ v$.add.address.$errors[0].$message }}
                      </div>
                    </div>
                  </div>
                  <div class="col-lg-6 col-md-6 col-12">
                    <div class="custom-form-group">
                      <label class="form-label">Pincode<span class="text-danger">*</span></label>
                      <input type="text" v-model="add.pincode" id="addpincodeInput" class="form-control text-capitalize"
                        placeholder="Enter Pincode" maxlength="6" @paste="onPasteMobile" @keypress="onlypincodeAddFormat" autocomplete="off" />
                      <div class="custom-error" v-if="v$.add.pincode.$error">
                        {{ v$.add.pincode.$errors[0].$message }}
                      </div>
                    </div>
                  </div>
                  <div class="col-lg-6 col-md-6 col-12">
                    <div class="row">
                      <div class="col-lg-8 col-md-8 col-12">
                        <div class="custom-form-group">
                          <label class="form-label">Upload Company Logo</label>
                          <FileUpload mode="basic" ref="companylogoimgadd" class="custom-upload-btn-ultima"
                            v-model="add.companylogoimg" accept="image/*" v-on:change="handleUploadCompanyLogoAdd()" />
                          <div class="custom-error" v-if="errorcompanylogoadd">{{ errorcompanylogoadd }}</div>
                        </div>
                      </div>
                      <div class="col-lg-4 col-md-4 col-12" v-if="createpreviewurl != null && createpreviewurl != ''">
                        <img :src="createpreviewurl.objectURL" class="
                                    rounded
                                    bg-light
                                    me-auto
                                    d-block
                                    voter-photo-img
                                  " alt="" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div v-if="showaddmodalloader">
              <div class="custom-modal-spinner-loader">
                <i class="pi pi-spin pi-spinner" style="font-size: 2rem"></i>
              </div>
            </div>
          </div>
        </div>
        <div class="modal-footer">
          <div class="col-12 text-end">
            <button type="button" @click="addcompanydetailbtn()" class="btn modal-bulk-next-btn" :disabled="showloader">
              <span v-if="!showloader">Submit</span>
              <div class="spinner-border text-light custom-spinner-loader-btn" role="status" v-if="showloader">
                <span class="visually-hidden">Loading...</span>
              </div>
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- Add Company modal end here -->
  <!-- Edit Company modal start here -->
  <div class="modal-mask" v-if="editcompanymodalstatus">
    <div class="
        modal-dialog modal-lg modal-dialog-scrollable modal-dialog-centered
        custom-modal-outer
      ">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="staticBackdropLabel">
            {{ modalid }} Company Details
          </h5>
          <button type="button" class="btn-close" @click="editCompanyDetailsModalClose()"></button>
        </div>
        <div class="modal-body">
          <div class="company-modal-fixed-height">
            <div class="row" v-if="!showmodalloader">
              <div class="col-12">
                <div class="row">
                  <div class="col-lg-6 col-md-6 col-12">
                    <div class="custom-form-group">
                      <label class="form-label">Marketing Name<span class="text-danger">*</span></label>
                      <input type="text" v-model="edit.marketingname" class="form-control"
                        placeholder="Enter Marketing Name" autocomplete="off" />
                      <div class="custom-error" v-if="v$.edit.marketingname.$error">
                        {{ v$.edit.marketingname.$errors[0].$message }}
                      </div>
                    </div>
                  </div>
                  <div class="col-lg-6 col-md-6 col-12">
                    <div class="custom-form-group">
                      <label class="form-label">Legal Name<span class="text-danger">*</span></label>
                      <input type="text" v-model="edit.legalname" class="form-control"
                        placeholder="Enter Legal Name" autocomplete="off" />
                      <div class="custom-error" v-if="v$.edit.legalname.$error">
                        {{ v$.edit.legalname.$errors[0].$message }}
                      </div>
                    </div>
                  </div>
                  <div class="col-lg-6 col-md-6 col-12">
                    <div class="custom-form-group">
                      <label class="form-label">Company Type<span class="text-danger">*</span></label>
                      <Multiselect v-model="edit.companytype" :options="companytypelist" :searchable="true" label="label"
                        placeholder="Select Company Type" class="multiselect-custom text-capitalize" :canClear="true"
                        :closeOnSelect="true" noOptionsText="No Result Found" :object="true" />
                      <div class="custom-error" v-if="v$.edit.companytype.$error">
                        {{ v$.edit.companytype.$errors[0].$message }}
                      </div>
                    </div>
                  </div>
                  <div class="col-lg-6 col-md-6 col-12">
                    <div class="custom-form-group">
                      <label class="form-label">GST No.<span class="text-danger">*</span></label>
                      <input type="text" v-model="edit.gstnumber" @input="validateEditGstNumber($event)"
                        class="form-control text-uppercase" placeholder="Enter GST No." maxlength="15"
                        autocomplete="off" />
                      <div class="custom-error" v-if="this.errmsg.erroreditgstnumber">{{
                        this.errmsg.erroreditgstnumber
                      }}</div>
                      <div class="custom-error" v-if="v$.edit.gstnumber.$error">
                        {{ v$.edit.gstnumber.$errors[0].$message }}
                      </div>
                    </div>
                  </div>
                  <div class="col-lg-6 col-md-6 col-12">
                    <div class="custom-form-group">
                      <label class="form-label">PAN No.<span class="text-danger">*</span></label>
                      <input type="text" v-model="edit.pannumber" class="form-control text-uppercase"
                        placeholder="Enter PAN No." @input="validateEditPanCardNumber($event)" maxlength="10"
                        autocomplete="off" />
                      <div class="custom-error" v-if="this.errmsg.erroreditpannumber">{{
                        this.errmsg.erroreditpannumber
                      }}</div>
                      <div class="custom-error" v-if="v$.edit.pannumber.$error">
                        {{ v$.edit.pannumber.$errors[0].$message }}
                      </div>
                    </div>
                  </div>
                  <div class="col-lg-6 col-md-6 col-12">
                    <div class="custom-form-group">
                      <label class="form-label">Area<span class="text-danger">*</span></label>
                      <input type="text" v-model="edit.area" class="form-control text-capitalize"
                        placeholder="Enter Area Name" autocomplete="off" />
                      <div class="custom-error" v-if="v$.edit.area.$error">
                        {{ v$.edit.area.$errors[0].$message }}
                      </div>
                    </div>
                  </div>
                  <div class="col-lg-6 col-md-6 col-12">
                    <div class="custom-form-group">
                      <label class="form-label">Address<span class="text-danger">*</span></label>
                      <textarea type="text" v-model="edit.address" class="form-control custom-text-area-scroll"
                        placeholder="Enter Address" autocomplete="off" rows="2" maxlength="250"></textarea>
                      <div class="custom-error" v-if="v$.edit.address.$error">
                        {{ v$.edit.address.$errors[0].$message }}
                      </div>
                    </div>
                  </div>
                  <div class="col-lg-6 col-md-6 col-12">
                    <div class="custom-form-group">
                      <label class="form-label">Pincode<span class="text-danger">*</span></label>
                      <input type="text" v-model="edit.pincode" id="editpincodeInput" class="form-control text-capitalize"
                        placeholder="Enter Pincode" maxlength="6" @keypress="onlypincodeEditFormat" autocomplete="off" />
                      <div class="custom-error" v-if="v$.edit.pincode.$error">
                        {{ v$.edit.pincode.$errors[0].$message }}
                      </div>
                    </div>
                  </div>
                  <div class="col-lg-6 col-md-6 col-12">
                    <div class="row">
                      <div class="col-lg-8 col-md-8 col-12">
                        <div class="custom-form-group">
                          <label class="form-label">Upload Company Logo</label>
                          <FileUpload mode="basic" accept="image/*" ref="companylogoimgedit"
                            class="custom-upload-btn-ultima" v-model="edit.companylogoimg"
                            v-on:change="handleUploadCompanyLogoEdit()" />
                          <div class="custom-error" v-if="errorcompanylogoedit">{{ errorcompanylogoedit }}</div>
                        </div>
                      </div>
                      <div class="col-lg-4 col-md-4 col-12" v-if="exitpreviewurl == null">
                        <img v-if="edit.importprofilefile != null && edit.importprofilefile != ''"
                          :src="edit.importprofilefile" class="
                                    rounded
                                    bg-light
                                    me-auto
                                    d-block
                                    voter-photo-img
                                  " alt="" />
                      </div>
                      <div class="col-lg-4 col-md-4 col-12" v-if="exitpreviewurl != null">
                        <img :src="exitpreviewurl.objectURL" class="
                                    rounded
                                    bg-light
                                    me-auto
                                    d-block
                                    voter-photo-img
                                  " alt="" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div v-if="showmodalloader">
              <div class="custom-modal-spinner-loader">
                <i class="pi pi-spin pi-spinner" style="font-size: 2rem"></i>
              </div>
            </div>
          </div>
        </div>
        <div class="modal-footer">
          <div class="col-12 text-end">
            <button type="button" @click="companydetailupdatebtn()" class="btn modal-bulk-next-btn"
              :disabled="showloader">
              <span v-if="!showloader">Update</span>
              <div class="spinner-border text-light custom-spinner-loader-btn" role="status" v-if="showloader">
                <span class="visually-hidden">Loading...</span>
              </div>
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- Edit Company modal end here -->
</template>
<script>
import { required, helpers, minLength } from "@vuelidate/validators";
import useValidate from "@vuelidate/core";
import ApiService from "../service/ApiService";
export default {
  data() {
    return {
      v$: useValidate(),
      addcompanymodalstatus: false,
      editcompanymodalstatus: false,
      companyDetail: "",
      companyList: "",
      showdataloader: false,
      showaddmodalloader: false,
      showmodalloader: false,
      rowId: "",
      companytypelist: [],
      errmsg: {},
      showloader: false,
      errpan: "",
      errStatus: false,
      errgst: "",
      errorcompanylogoadd: '',
      errorcompanylogoedit: '',
      modalid: '',
      errors: "",
      add: {
        marketingname: "",
        legalname: "",
        area: "",
        pincode: "",
        address: "",
        gstnumber: "",
        pannumber: "",
        companytype: '',
        companytypelist: [],
        companylogoimg: null,
      },
      edit: {
        marketingname: "",
        legalname: "",
        area: "",
        pincode: "",
        address: "",
        gstnumber: "",
        pannumber: "",
        companytype: '',
        companytypelist: [],
        companylogoimg: null,
        importprofilefile: '',
      },
      createpreviewurl: null,
      exitpreviewurl: null,
      switchuncheck: false,
      dynamicTrueActiveValue: 1,
      dynamicFalseInactiveValue: 0,
    };
  },
  ApiService: null,
  created() {
    this.ApiService = new ApiService();
  },
  mounted() {
    this.getfirmcompanylist();
    this.getcompanytypes();
  },
  validations() {
    return {
      add: {
        marketingname: {
          required: helpers.withMessage("Please enter marketing name", required),
        },
        legalname: {
          required: helpers.withMessage("Please enter legal name", required),
        },
        area: {
          required: helpers.withMessage("Please enter area name", required),
        },
        pincode: {
          required: helpers.withMessage("Please enter pincode", required),
          minLength: helpers.withMessage(
            "Pincode should be min 6 length",
            minLength(6)
          ),
        },
        companytype: {
          required: helpers.withMessage("Please select company type", required),
        },
        gstnumber: {
          required: helpers.withMessage("Please enter GST number", required),
        },
        pannumber: {
          required: helpers.withMessage("Please enter PAN number", required),
        },
        address: {
          required: helpers.withMessage("Please enter address", required),
        },
      },
      edit: {
        marketingname: {
          required: helpers.withMessage("Please enter marketing name", required),
        },
        legalname: {
          required: helpers.withMessage("Please enter legal name", required),
        },
        area: {
          required: helpers.withMessage("Please enter area name", required),
        },
        pincode: {
          required: helpers.withMessage("Please enter pincode", required),
          minLength: helpers.withMessage(
            "Pincode should be min 6 length",
            minLength(6)
          ),
        },
        companytype: {
          required: helpers.withMessage("Please select company type", required),
        },
        gstnumber: {
          required: helpers.withMessage("Please enter GST number", required),
        },
        pannumber: {
          required: helpers.withMessage("Please enter PAN number", required),
        },
        address: {
          required: helpers.withMessage("Please enter address", required),
        },
      },
    };
  },
  methods: {
    getfirmcompanylist(e) {
      this.showdataloader = true;
      this.ApiService.getfirmcompanylist(e).then((data) => {
        if (data.status == 200) {
          this.companyList = data.data;
          this.showdataloader = false;
        } else {
          this.companyList = "";
          this.showdataloader = false;
        }
      });
    },
    getcompanytypes(e) {
      this.ApiService.getcompanytypes(e).then((data) => {
        if (data.status == 200) {
          this.companytypelist = data.data;
        }
      });
    },
    changeStatus(data) {
      let fields = {};
      fields["al1"] = data.al1;
      if (data.al14 == 0 || data.al14 == false) {
        fields["al14"] = 0;
      }
      if (data.al14 == 1 || data.al14 == true) {
        fields["al14"] = 1;
      }
      this.ApiService.updateBillingCompany(fields).then((items) => {
        if (items.status === 200) {
          var successMsg = items.message;
          this.$toast.open({
            message: successMsg,
            type: "success",
            duration: 3000,
            position: "top-right",
          });
          this.getfirmcompanylist();
        } else {
          var errorMsg = items.message;
          this.$toast.open({
            message: errorMsg,
            type: "error",
            duration: 3000,
            position: "top-right",
          });
        }
      });
    },
    addCompanyModalOpen() {
      this.addcompanymodalstatus = true;
      this.showaddmodalloader = false;
      this.add.marketingname = "";
      this.add.legalname = "";
      this.add.area = "";
      this.add.pincode = "";
      this.add.address = "";
      this.add.gstnumber = "";
      this.add.pannumber = "";
      this.add.companytype = '';
      this.add.companylogoimg = null;
      this.createpreviewurl = null;
    },
    addCompanyModalClose() {
      this.addcompanymodalstatus = false;
    },
    addcompanydetailbtn() {
      this.v$.add.$validate();
      var formData = new FormData();
      formData.append("al2", this.add.marketingname);
      formData.append("al3", this.add.legalname);
      formData.append("al5", this.add.pannumber);
      formData.append("al6", this.add.gstnumber);
      formData.append("al7", this.add.address);
      formData.append("al8", this.add.companytype.value);
      formData.append("al9", this.add.companytype.label);
      formData.append("al12", this.add.area);
      formData.append("al13", this.add.pincode);
      if (this.add.companylogoimg) {
        formData.append("al4", this.add.companylogoimg);
      }
      if (!this.v$.add.$error && this.errStatus == false && !this.errorcompanylogoadd) {
        this.showloader = true;
        this.ApiService.addfirmcompany(formData).then((data) => {
          if (data.status == 200) {
            this.showloader = false;
            var successMsg = data.message;
            this.$toast.open({
              message: successMsg,
              type: "success",
              duration: 3000,
              position: "top-right",
            });
            this.addcompanymodalstatus = false;
            setTimeout(() => {
              this.v$.$reset();
            }, 0);
            this.add.marketingname = '';
            this.add.legalname = '';
            this.add.pannumber = '';
            this.add.gstnumber = '';
            this.add.address = '';
            this.add.companytype = '';
            this.add.area = '';
            this.add.pincode = '';
            this.add.companylogoimg = null;
            this.getfirmcompanylist();
          } else {
            this.showloader = false;
            var errorMsg = data.message;
            this.$toast.open({
              message: errorMsg,
              type: "error",
              duration: 3000,
              position: "top-right",
            });
          }
        });
      } else {
        this.$error;
      }
    },
    editCompanyDetailsModalOpen(e) {
      this.editcompanymodalstatus = true;
      this.showmodalloader = true;
      this.rowId = e.al1;
      this.edit.marketingname = e.al2;
      this.edit.legalname = e.al3;
      this.edit.area = e.al12;
      this.edit.pincode = e.al13;
      this.edit.address = e.al7;
      this.edit.gstnumber = e.al6;
      this.edit.pannumber = e.al5;
      this.edit.companytype = { value: e.al8, label: e.al9 };
      this.edit.importprofilefile = e.al4;
      this.showmodalloader = false;
    },
    editCompanyDetailsModalClose() {
      this.editcompanymodalstatus = false;
    },
    companydetailupdatebtn() {
      this.v$.edit.$validate();
      var formData = new FormData();
      formData.append("al1", this.rowId);
      formData.append("al2", this.edit.marketingname);
      formData.append("al3", this.edit.legalname);
      formData.append("al5", this.edit.pannumber);
      formData.append("al6", this.edit.gstnumber);
      formData.append("al7", this.edit.address);
      formData.append("al8", this.edit.companytype.value);
      formData.append("al9", this.edit.companytype.label);
      formData.append("al12", this.edit.area);
      formData.append("al13", this.edit.pincode);
      if (this.edit.companylogoimg) {
        formData.append("al4", this.edit.companylogoimg);
      }
      if (!this.v$.edit.$error && this.errStatus == false && !this.errorcompanylogoedit) {
        this.showloader = true;
        this.ApiService.updatefirmcompany(formData).then((data) => {
          if (data.status == 200) {
            this.showloader = false;
            var successMsg = data.message;
            this.$toast.open({
              message: successMsg,
              type: "success",
              duration: 3000,
              position: "top-right",
            });
            this.editcompanymodalstatus = false;
            setTimeout(() => {
              this.v$.$reset();
            }, 0);
            this.getfirmcompanylist();
          } else {
            this.showloader = false;
            var errorMsg = data.message;
            this.$toast.open({
              message: errorMsg,
              type: "error",
              duration: 3000,
              position: "top-right",
            });
          }
        });
      } else {
        this.$error;
      }
    },
    handleUploadCompanyLogoAdd() {
      var file = this.$refs.companylogoimgadd.files[0];
      let allowedExtensions = /(\.jpg|\.jpeg|\.png)$/i;
      if (!file.size > 2048) {
        this.errorcompanylogoadd = 'File size cannot exceed more than 2MB';
      } else if (!file.name.match(allowedExtensions)) {
        this.errorcompanylogoadd = 'Invalid file type';
      } else {
        this.errorcompanylogoadd = '';
        this.add.companylogoimg = this.$refs.companylogoimgadd.files[0];
        this.createpreviewurl = this.add.companylogoimg;
      }
    },
    handleUploadCompanyLogoEdit() {
      var file = this.$refs.companylogoimgedit.files[0];
      let allowedExtensions = /(\.jpg|\.jpeg|\.png)$/i;
      if (!file.size > 2048) {
        this.errorcompanylogoedit = 'File size cannot exceed more than 2MB';
      } else if (!file.name.match(allowedExtensions)) {
        this.errorcompanylogoedit = 'Invalid file type';
      } else {
        this.errorcompanylogoedit = '';
        this.edit.companylogoimg = this.$refs.companylogoimgedit.files[0];
        this.exitpreviewurl = this.edit.companylogoimg;
      }
    },
    validateAddPanCardNumber(e) {
      var regex = /([A-Z]){5}([0-9]){4}([A-Z]){1}$/;
      let add_pan_number = e.target.value.toUpperCase();
      this.erroraddpannumber = add_pan_number;
      if (add_pan_number && !add_pan_number.match(regex)) {
        this.errmsg["erroraddpannumber"] = "Please enter valid pan number";
        this.errStatus = true;
      } else {
        this.errmsg["erroraddpannumber"] = "";
        this.errStatus = false;
      }
    },
    validateEditPanCardNumber(e) {
      var regex = /([A-Z]){5}([0-9]){4}([A-Z]){1}$/;
      let edit_pan_number = e.target.value.toUpperCase();
      this.erroreditpannumber = edit_pan_number;
      if (edit_pan_number && !edit_pan_number.match(regex)) {
        this.errmsg["erroreditpannumber"] = "Please enter valid pan number";
        this.errStatus = true;
      } else {
        this.errmsg["erroreditpannumber"] = "";
        this.errStatus = false;
      }
    },
    validateAddGstNumber(e) {
      var regexGst = /[0-9]{2}[A-Z]{5}[0-9]{4}[A-Z]{1}[1-9A-Z]{1}Z[0-9A-Z]{1}$/;
      let add_gst_number = e.target.value.toUpperCase();
      this.erroraddgstnumber = add_gst_number;
      if (add_gst_number && !add_gst_number.match(regexGst)) {
        this.errmsg["erroraddgstnumber"] = "Please enter valid GST number";
        this.errStatus = true;
        this.add.pannumber = '';
      } else {
        this.add.pannumber = add_gst_number.substr(2, 10);
        this.errmsg["erroraddgstnumber"] = "";
        this.errStatus = false;
      }
    },
    validateEditGstNumber(e) {
      var regexGst = /[0-9]{2}[A-Z]{5}[0-9]{4}[A-Z]{1}[1-9A-Z]{1}Z[0-9A-Z]{1}$/;
      let edit_gst_number = e.target.value.toUpperCase();
      this.erroreditgstnumber = edit_gst_number;
      if (edit_gst_number && !edit_gst_number.match(regexGst)) {
        this.errmsg["erroreditgstnumber"] = "Please enter valid GST number";
        this.errStatus = true;
        this.edit.pannumber = '';
      } else {
        this.edit.pannumber = edit_gst_number.substr(2, 10);
        this.errmsg["erroreditgstnumber"] = "";
        this.errStatus = false;
      }
    },
    onlypincodeEditFormat($event) {
      let keyCode = $event.keyCode ? $event.keyCode : $event.which;
      if (keyCode < 48 || keyCode > 57) {
        $event.preventDefault();
      }
      var editpincodeInput = document.getElementById("editpincodeInput");
      if ((editpincodeInput.value == '' || editpincodeInput.length > 1) && (keyCode == 48)) {
        $event.preventDefault();
      }
    },
    onPasteMobile(event) {
      let mobvalue = event.clipboardData.getData('text');
      if (/^[0-9]+$/.test(mobvalue)) return true;
      else event.preventDefault();
    },
    onlypincodeAddFormat($event) {
      let keyCode = $event.keyCode ? $event.keyCode : $event.which;
      if (keyCode < 48 || keyCode > 57) {
        $event.preventDefault();
      }
      var addpincodeInput = document.getElementById("addpincodeInput");
      if ((addpincodeInput.value == '' || addpincodeInput.length > 1) && (keyCode == 48)) {
        $event.preventDefault();
      }
    },
  },
};
</script>
<style scoped>
.company-legal-name {
  font-family: "AcuminPro-SemiBold";
  letter-spacing: 0px;
  color: #2b2e38;
  font-size: 16px;
  line-height: 20px;
  white-space: break-spaces;
}

.company-detail-box-outer .company-profile-img {
  pointer-events: none;
}

.company-detail-box-outer {
  position: relative;
  height: 525px;
  overflow: auto;
}

.company-box-outer {
  padding: 16px 16px 0;
  border: 1px solid #e0e5ed;
  border-radius: 4px;
  margin-top: 20px;
  background: #FFFFFF 0% 0% no-repeat padding-box;
}

.company-box-outer:last-child {
  margin-bottom: 20px;
}

.customer-label-group {
  margin-bottom: 20px;
}

.customer-label-group .form-label {
  font-family: "AcuminPro-Regular";
  font-size: 13px;
  letter-spacing: 0px;
  color: #4a5463;
  line-height: 13px;
  margin-bottom: 6px;
}

.customer-label-group .from-label-value {
  font-family: "AcuminPro-SemiBold";
  font-size: 14px;
  letter-spacing: 0.28px;
  color: #2b2e38;
  line-height: 20px;
}

.company-detail-box-outer::-webkit-scrollbar {
  display: block;
}

.company-detail-box-outer::-webkit-scrollbar {
  width: 0.3em;
  height: 0.3em;
}

.company-detail-box-outer::-webkit-scrollbar-thumb {
  background-color: rgb(113 115 119 / 49%);
  border-radius: 3px;
}

.status-active {
  background: #c8e6c9;
  border-radius: 2px;
  letter-spacing: 0.3px;
  color: #256029;
  opacity: 1;
  font-size: 12px;
  font-family: "AcuminPro-Bold";
  padding: 0.3em 0.2rem 0.2em 0.2rem;
  text-transform: capitalize;
  border: 1px solid #4daa53;
  line-height: 12px;
}

.status-inactive {
  background: #ffcdd2;
  border-radius: 2px;
  letter-spacing: 0.3px;
  color: #c63737;
  opacity: 1;
  font-size: 12px;
  font-family: "AcuminPro-Bold";
  padding: 0.3em 0.2rem 0.2em 0.2rem;
  text-transform: capitalize;
  border: 1px solid #d7a5a5;
  line-height: 12px;
}

.modal-bulk-next-btn.btn {
  background: #5266e5 0% 0% no-repeat padding-box;
  border-color: #5266e5;
  border-radius: 4px;
  letter-spacing: 0.26px;
  color: #ffffff;
  font-size: 13px;
  line-height: 20px;
  padding: 6px 35px;
  font-family: "AcuminPro-Regular";
  width: 115px;
}

.modal-bulk-next-btn:focus {
  box-shadow: none;
}
</style>